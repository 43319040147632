@import '~bootstrap/scss/bootstrap';

$Font_Size_Smaller: 0.73rem;
$Font_Size_Dropdown_Menu: 0.85rem;
$Font_Size_Header_1: 2rem;
$Font_Size_Header_2: 1.5rem;

$blue-pale : #ccd3ff;

$Sidebar_Background: #242539;
$Sidebar_Font_Color: white;
$Sidebar_Button_Highlight: #2B2D42;
$Sidebar_Button_Accent: $danger;

$Standard_Font_Color: #2B2E42;
$Muted_Font_Color: #B8B8BA;
$Standard_Scroller_Width: 450px;

$Highlight_Gray:#F1F3F7;
$Border_Gray: #D2D2D4;
$Input_Border_Radius: 0.2rem;

$Input_Border_Color: #D8D8DA;

$Data_Card_Background: #F2F2F2;
$Data_Card_Font_Color: #6D6D6E;
$Data_Card_Table_Highlight: #C8D6E8;
$Data_Card_Border_Color: #B8B8BA;
$Data_Card_Border_Radius: 0.5rem;

$Danger: #bd2130;
$Primary: #2F80ED;

$Scrollbar_Color: #9FA2B4;

