
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@ng-select/ng-select/themes/default.theme.css";


/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '../node_modules/spinthatshit/src/loaders.scss';
@import "_variables";

$sidebar_width: 170px;

html, body {

  color: #2B2E42;
  height: 100%; 
  --scrollbarBG: $Data_Card_Font_Color;
  --thumbBG: transparent;
}

body {
  margin: 0; 
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.8rem;
}

*[ng-reflect-router-link]:focus {
  outline: none;
}

.flex-basis-30 {
  width: 30% !important;
}

.flex-basis-50 {
  width: 50% !important;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.text-sm {
  font-size: $Font_Size_Smaller;
}

.material-icons {
  font-size: 1rem;
}

.badge {
  line-height: 1.5 !important;

  .fas {
    margin-top: 3px;
  }
}

.btn {
  padding: 0.75rem 1rem !important;
  min-height: 1.5rem !important;
  border-radius: 0.25rem !important;
  .material-icons {
    vertical-align: middle;
    padding-bottom: 3px;
  }
}

.btn-secondary {
  background-color: white !important;
  color: #AA9399 !important;
  border: 1px solid #DDD !important;
  transition: 0.15s all ease-in-out;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background-color: #CCC !important;
  transition: 0.15s all ease-in-out;
}

.text-white {
  color: white;
  font-weight: bold;
}

.text-lighter {
  color: #929399;
}

.drop-shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.control-label {
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.75px;
}

.form-control {
  border-radius: 0.25rem !important;
  border-color: $Input_Border_Color !important;
  height: 2rem !important;
  font-size: 0.75rem !important; 
  padding: 0.25rem 0.4rem !important;
  transition: 0.15s ease-in-out all;
}

select.form-control {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 25%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.form-check-label {
  margin-top: 0.18rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.6;
  filter: brightness(0.5);
}

input.form-control::placeholder {
  font-size: 0.8rem;
  font-weight: 300;
  color: $Input_Border_Color !important;
}

.input-group {
  border-radius: $Input_Border_Radius !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;

  .fas {
    color: $Input_Border_Color;
  }

  .form-control-left {
    border-right: 0px solid white !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .form-control-right {
    border-left: 0px solid white !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  
  .icon-left {
    border-top-left-radius: $Input_Border_Radius;
    border-bottom-left-radius: $Input_Border_Radius;
    border-top: 1px solid $Input_Border_Color;
    border-left: 1px solid $Input_Border_Color;
    border-bottom: 1px solid $Input_Border_Color;
  }

  .icon-right {
    border-top-right-radius: $Input_Border_Radius;
    border-bottom-right-radius: $Input_Border_Radius;
    border-top: 1px solid $Input_Border_Color;
    border-right: 1px solid $Input_Border_Color;
    border-bottom: 1px solid $Input_Border_Color;
  }

  .icon-left > .fas, .icon-right > .fas {
    transition: color 0.15s ease-in-out, box-shadow 0.15s;
  }
}

.input-group:focus-within {
  border-radius: $Input_Border_Radius;
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
  
  .form-control {
    outline: none !important;
    box-shadow: none !important;
    transition: 0.15s ease-in-out all;
  }

  // .icon-left, .icon-right, .form-control:focus, .form-control:active {
  //   background-color: #fafad2;
  //   transition: 0.15s ease-in-out all;
  // }

  .icon-left > .fas, .icon-right > .fas {
    color: $primary !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
  }
}

.muted {
  color:  $Muted_Font_Color;
}

.table {
  .form-cells {
    background-color: #fafad2;

    .form-control {
      height: 100% !important;
      border: none !important;
      box-shadow: none !important;
      background-color:#fafad2 !important;
      display: inline-block !important;
      position: relative !important;
      border-radius: 0px !important;
    }

    .form-control:active, .form-control:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }

  .form-cells:focus-within {
    .form-control {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.borderless {
  border: none !important;

  tr, th, td {
    border: none !important;
  }
}

.btn {
  font-size: 0.75rem !important;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.btn-light {
  color: $Data_Card_Border_Color !important;
  border: 1px solid $Data_Card_Border_Color !important;
  background-color: rgba(200,200,200,0.01) !important;
}

.btn-light:hover, .btn-light:active, .btn-light:focus {
  color: darkgray !important;
  background-color: rgba(0,0,0,0.1) !important;
}

.card-body {
  padding-bottom: 0.5rem !important;
}

.dropdown-toggle {
  user-select: none;
  cursor: pointer;
}

.dropdown-menu {
  padding: 0 !important;
  margin-top: 0.75rem;
  border-radius: $Input_Border_Radius;

  .dropdown-item {
    font-size: $Font_Size_Dropdown_Menu;
    color: $Data_Card_Font_Color !important;
    user-select: none;
    cursor: pointer;

    .fas {
      color: $Data_Card_Font_Color;
      transition: color 0.15s ease-in-out;
    }
  }

  .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
    background: $Highlight_Gray !important;
    color: $Data_Card_Font_Color !important;

    .fas {
      color: $primary !important;
      transition: color 0.15s ease-in-out;
    }
  }
}

.elevate-1 {
  box-shadow: 0 1px 0px rgba(0,0,0,0.12), 0 1px 0px rgba(0,0,0,0.24);
  z-index: 10;
}

.send-to-back {
  z-index: 0;
}


.sidebar-width {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}

.drawer-header {
  font-size: 1.5rem;
}

.loader12 {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: relative;
  animation: loader12-u6338d783 1s linear alternate infinite;
  top: 50%;
  margin: -50px auto 0; 
  color: $Data_Card_Font_Color;
}

.clickable-text {
  user-select: none;
  cursor: pointer; 
  color: $primary;
}

.clickable-text:hover {
  text-decoration: underline;
}

.cart-button {
  user-select: none;
  cursor: pointer;
}

.add-to-cart-icon {
  width: 25px;
  height: 25px;
  background:url('./assets/Icon_Box_add.svg');
  background-size: contain;
  cursor: pointer;
  user-select: none;
}

.add-to-cart-icon:hover {
  width: 25px;
  height: 25px;
  background:url('./assets/Icon_Box_add_plus.svg');
  background-size: contain;
}

// .add-to-cart-icon {
  
// }

@keyframes loader12-u6338d783 {
  0% {
    box-shadow: -60px 40px 0 2px #80bdff, -30px 40px 0 0 rgba(0, 82, 236, 0.2), 0 40px 0 0 rgba(0, 82, 236, 0.2), 30px 40px 0 0 rgba(0, 82, 236, 0.2), 60px 40px 0 0 rgba(0, 82, 236, 0.2); }
  25% {
    box-shadow: -60px 40px 0 0 rgba(0, 82, 236, 0.2), -30px 40px 0 2px #80bdff, 0 40px 0 0 rgba(0, 82, 236, 0.2), 30px 40px 0 0 rgba(0, 82, 236, 0.2), 60px 40px 0 0 rgba(0, 82, 236, 0.2); }
  50% {
    box-shadow: -60px 40px 0 0 rgba(0, 82, 236, 0.2), -30px 40px 0 0 rgba(0, 82, 236, 0.2), 0 40px 0 2px #80bdff, 30px 40px 0 0 rgba(0, 82, 236, 0.2), 60px 40px 0 0 rgba(0, 82, 236, 0.2); }
  75% {
    box-shadow: -60px 40px 0 0 rgba(0, 82, 236, 0.2), -30px 40px 0 0 rgba(0, 82, 236, 0.2), 0 40px 0 0 rgba(0, 82, 236, 0.2), 30px 40px 0 2px #80bdff, 60px 40px 0 0 rgba(0, 82, 236, 0.2); }
  100% {
    box-shadow: -60px 40px 0 0 rgba(0, 82, 236, 0.2), -30px 40px 0 0 rgba(0, 82, 236, 0.2), 0 40px 0 0 rgba(0, 82, 236, 0.2), 30px 40px 0 0 rgba(0, 82, 236, 0.2), 60px 40px 0 2px #80bdff; } }


.progress {
  position: relative;
  height: 3px !important;
  display: block;
  width: 100%;
  background-color: rgba(0,0,0,0);
  border-radius: 0px;
  background-clip: padding-box;
  overflow: hidden; }
  .progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color:  $primary;
    transition: width .3s linear; }
  .progress .indeterminate {
    background-color: $danger; }
    .progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s; }

.validate-error{
    box-shadow: inset 0 0 1px rgb(255, 0, 0);
    border-color: #FF0000 !important;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; }
}    

.loading-bar-compensation {
  margin-top: -3px !important;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 3% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.hyperlink  {
  color: #1974E6;
  cursor: pointer;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.overlay-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: RGBA(0,0,0,0.5);
}

.dialog-container {
  position: absolute;
  top: 5%;
  left: 11.5%;
  right: 11.5%;
  bottom: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dialog-card {
  display: flex;
  flex-direction: column;  
  border-radius: $Data_Card_Border_Radius $Data_Card_Border_Radius 0 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
}

.dialog-header {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
  background: $Data_Card_Background;
}

.dialog-body {
  background: white;
  overflow-x: hidden;
  overflow-y: hidden; 
}

.dialog-footer {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 0 0 $Data_Card_Border_Radius $Data_Card_Border_Radius;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  background: $Data_Card_Background;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $danger;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $danger transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//Scrollbar stuffs
* {
  scrollbar-width: thin;
  scrollbar-color: grey lightgrey;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $Scrollbar_Color;
  border-radius: 3px;
}

.flex-33 {
  width: 33.33%;
}